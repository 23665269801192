import Script from 'next/script'
import { GA_TRACKING_ID, pageview } from 'utils/gtag'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const Analytics: React.FC = () => {
  const router = useRouter()

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // Ref: https://nextjs.org/docs/messages/next-script-for-ga
  // Ref: Shouldn't use Script tag in _document https://nextjs.org/docs/messages/no-script-in-document

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'GA_MEASUREMENT_ID', {
              page_path: window.location.pathname,
            });
          `}
      </Script>
    </>
  )
}
