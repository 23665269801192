import { ToastPosition } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'
import { Roboto } from 'next/font/google'
import theme from 'styles/theme'

export const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  display: 'swap',
  subsets: ['latin'],
})
const toastTheme = theme({
  heading: `${roboto.style.fontFamily}, sans-serif;`,
  body: `${roboto.style.fontFamily}, sans-serif;`,
})
const toast = createStandaloneToast({ theme: toastTheme })

interface Props {
  title?: string
  description: string
  status?: 'info' | 'warning' | 'success' | 'error'
  position?: ToastPosition
}
const backgroundColors = {
  success: '#2F855A',
  warning: '#C05621',
  error: '#C53030',
  info: '#2b6cb0',
}

export const notificationController = {
  show: ({ title, description, status, position }: Props) => {
    toast({
      title,
      description,
      status: status || 'info',
      position: position || 'bottom-right',
      isClosable: true,
      containerStyle: {
        backgroundColor: backgroundColors[status],
      }
    })
  },
}
