import { NextSeo } from 'next-seo'

export default function DefaultSEO() {
  return (
    <NextSeo
      title="Intelligent Tools For Your Domain Names."
      description="The Best AI-powered and advanced domain names tools on the web. DNS, domain names, name servers, IPs, and tools for searching and monitoring domain names, smart domain backordering analysis, Expired domain auctions and more!"
      openGraph={{
        url: 'https://bishopi.io/',
        title: 'Intelligent Tools For Your Domain Names.',
        description:
          'The Best AI-Powered and advanced domain and SEO tools . Smart domain backordering analysis, expired domain auctions and more!',
      }}
      additionalMetaTags={[
        {
          name: 'keywords',
          content: 'bishopi domain seo dns whois nameserver ip',
        },
      ]}
      additionalLinkTags={[
        {
          rel: 'icon',
          href: '/favicon.png',
        },
        {
          rel: 'apple-touch-icon',
          href: '/favicon.png',
          sizes: '76x76',
        },
      ]}
    />
  )
}
