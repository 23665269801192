import React from 'react'
import dynamic from 'next/dynamic'

import { Elements } from '@stripe/react-stripe-js'
import { ChakraProvider } from '@chakra-ui/provider'

import 'focus-visible/dist/focus-visible'
import type { AppProps } from 'next/app'

import theme from 'styles/theme'
import DefaultSEO from 'components/default-seo'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import AOS from 'aos'
import 'api/http'

import 'aos/dist/aos.css'
import 'styles/globals.scss'
import { NextPage } from 'next'
import { Layout } from 'models/page.interface'
import { Analytics } from 'components/Analytics'
import { useRouter } from 'next/router'
import { Roboto } from 'next/font/google'

export const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  display: 'swap',
  subsets: ['latin'],
})

const chakraTheme = theme({
  heading: `${roboto.style.fontFamily}, sans-serif`,
  body: `${roboto.style.fontFamily}, sans-serif`,
  code: `${roboto.style.fontFamily}, monospace`,
})
interface ReactQueryDevtoolsProps {
  initialIsOpen: boolean
}
const ReactQueryDevtools = dynamic<ReactQueryDevtoolsProps>(() =>
  import('react-query/devtools').then((mod) => mod.ReactQueryDevtools)
)

const CookieBanner = dynamic(() =>
  import('components/common/CookieBanner/CookieBanner').then(
    (mod) => mod.CookieBanner
  )
)

type NextPageWithLayout = NextPage & Layout

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  },
})

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  React.useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
      offset: 50,
    })
  }, [])
  const getLayout = Component.getLayout || ((page) => page) // I was following the NextJS Guide: https://nextjs.org/docs/basic-features/layouts

  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          {!isProduction && <ReactQueryDevtools initialIsOpen={false} />}
          <ChakraProvider theme={chakraTheme}>
            <style jsx global>{`
              :root {
                --roboto-font: ${roboto.style.fontFamily};
                --poppins-font: ${roboto.style.fontFamily};
                --inconsolata-font: ${roboto.style.fontFamily};
                --rubik-font: ${roboto.style.fontFamily}
              }
            `}</style>
            <StripeProvider>
              <DefaultSEO />
              {isProduction && <Analytics />}
              {getLayout(<Component {...pageProps} />)}
            </StripeProvider>
            <CookieBanner />
          </ChakraProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

const StripeProvider: React.FC<{ children: any }> = ({ children }) => {
  const router = useRouter()
  const isRoot = router.asPath === '/'

  if (isRoot) return <>{children}</>

  return (
    <Elements
      stripe={
        {
          then: (resolve) => {
            import('@stripe/stripe-js').then(async (stripe) => {
              resolve(
                await stripe.loadStripe(
                  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
                )
              )
            })
          },
        } as any
      }
    >
      {children}
    </Elements>
  )
}

export default MyApp
