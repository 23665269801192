export enum RouteEnum {
  HOME = '/',
  LOGIN = '/login',
  SIGNUP = '/sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  PRICING = '/pricing',
  ABOUT = '/about',
  DASHBOARD_CUSTOMER_PORTAL = '/dashboard/customer-portal',
  DASHBOARD_BACKORDERS = '/dashboard/backorders',
  DASHBOARD_DOMAINS = '/dashboard/domains',
  DASHBOARD_PERSONAL_INFO = '/dashboard/personal-info',
  DASHBOARD_PAYMENTS = '/dashboard/payments',
  DOMAINS = '/domains',
  AFFILIATE_PROGRAM = '/affiliate',
  APIS = '/apis',
  DOMAIN_TRENDS = '/trends',
  DOMAIN_KEYWORD_TREND = '/trends/detail',

  AUCTIONS = '/auctions',

  TERMS_OF_USAGE = '/terms-of-usage',
  ORDER = '/order',
  PRIVACY_POLICY = '/privacy-policy',
  CONTACT_US = '/contact-us',

  TOOLS_SEARCH_ALL_REGISTERED_DOMAINS = '/tools/all-registered-domain-search',
  TOOLS_AGE_LOOKUP = '/tools/domain-age-lookup',
  TOOLS_WHOIS_LOOKUP = '/tools/whois-lookup',
  TOOLS_AVAILABILITY_LOOKUP = '/tools/availability-lookup',
  TOOLS_BRAND_MONITOR = '/tools/brand-monitor',
  TOOLS_DOMAIN_SALES_LOOKUP = '/tools/domain-sales-lookup',
  TOOLS_FRESH_DROPS = '/tools/fresh-drops',
  TOOLS_DOMAIN_MONITOR = '/tools/domain-monitor',
  TOOLS_BACKLINKS_LOOKUP = '/tools/backlinks-checker',
  TOOLS_SEO_METRICS = '/tools/track-seo-metrics',
  TOOLS_SEO_ANALYSIS = '/tools/domain-seo-analysis',

  FAQ = '/faq',

  CHECKOUT = '/checkout',
  BLOG = '/blog',

  ERROR404 = '/404',
}
