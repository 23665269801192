import { extendTheme } from '@chakra-ui/react'

const theme = (fonts) =>
  extendTheme({
    colors: {
      primary: {
        200: '#FFF4D7',
        300: '#FFEED4',
        400: '#F9D783',
      },
      background: {
        200: '#EFF3F9',
        400: '#D5EAE5',
      },
      button: {
        200: '#0D0303',
        400: '#E1E1E1',
      },
      text: {
        200: '#818181',
        300: '#000B33',
        500: '#908F8F',
        800: '#020101',
      },
      subHeading: {
        300: '#377CFD',
      },
      green: '#0AC12D',
    },
    fonts,
  })

export default theme
