import axios, { AxiosResponse } from 'axios'
import { notificationController } from '../notificationController/notificationController'
import Router from 'next/router'
import { RouteEnum } from '../enums/RouteEnum'
import { getCookie } from 'utils/getCookie'
import { deleteCookie } from 'utils/deleteCookie'
import { captureException, captureMessage } from '@sentry/nextjs'
import { queryClient } from 'pages/_app'
import { APIHooksKeysEnum } from 'enums/APIHooksKeysEnum'

axios.defaults.timeout = 20000

axios.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    const accessToken = getCookie('access')

    return {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: accessToken ? `Token ${accessToken}` : '',
      },
    }
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'application/json',
    },
  }
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResp: AxiosResponse = error?.response
    const errorMsg =
      errorResp?.data?.message?.error ||
      errorResp?.data?.message ||
      'Unknown Error'

    if (errorResp?.status === 401) {
      deleteCookie('access')
      queryClient.removeQueries({ queryKey: [APIHooksKeysEnum.GET_USER] })
      notificationController.show({
        title: 'Please Login again to continue',
        description: errorMsg,
        status: 'warning',
      })
      Router.push(`${RouteEnum.LOGIN}/?from=${Router.pathname}`)
    }

    const responseError = {
      ...error,
      message: errorMsg,
    }

    if (errorResp?.status >= 500 && errorResp?.data)
      captureException(errorResp.data)

    return Promise.reject(responseError)
  }
)
